import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NbMenuItem, NbMenuService, NbSidebarService } from "@nebular/theme";
import { filter, takeUntil } from "rxjs";
import { AuthService } from "../services/auth.service";
import { User } from "../models/user.model";

@Component({
    selector: 'app-page',
    templateUrl: 'page.component.html',
    styleUrls: ['page.component.scss']
})
export class PageComponent {

    public menu: NbMenuItem[] = [];
    public user: User | null = null;

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        protected authService: AuthService,
        protected router: Router
    ) {
        if (window.innerWidth <= 1200 && window.innerWidth > 576) {
            this.menuService.onItemSelect().subscribe((item) => {
                this.sidebarService.compact('menu-sidebar')
            });
        } else if (window.innerWidth <= 576) {
            this.menuService.onItemSelect().subscribe((item) => {
                this.sidebarService.collapse('menu-sidebar')
            });
        }

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((ev: any) => {
            if (ev.url == '/') {
                this.router.navigateByUrl('/dashboard');
            }
        });

        this.buildMenu();
        this.user = this.authService.getConnectedUser();
    }

    private buildMenu(): void {
        this.menu = [
            {
                title: 'Tableau de bord',
                group: true,
            },
            {
                title: 'Dashboard',
                link: '/dashboard',
                icon: 'bar-chart-outline',
                pathMatch: 'prefix',
            },
            {
                title: 'Bornes & Magasins',
                group: true,
            },
            {
                title: 'Bornes',
                link: '/terminal',
                icon: 'smartphone-outline',
                pathMatch: 'prefix',
            },
            {
                title: 'Magasin',
                icon: 'shopping-cart-outline',
                link: '/store',
                pathMatch: 'prefix'
            },
            {
                title: 'Configuration par défaut',
                link: '/default-settings',
                icon: 'settings-2-outline',
                pathMatch: 'prefix',
            },
            {
                title: 'Données référentielles',
                group: true,
            },
            {
                title: 'Enseigne',
                icon: 'award-outline',
                link: '/sign',
                pathMatch: 'prefix'
            },
            {
                title: 'Cartouches',
                icon: 'printer-outline',
                link: '/cartridge',
                pathMatch: 'prefix'
            },
            {
                title: 'Réponses au casino',
                icon: 'clipboard-outline',
                link: '/casino-response',
                pathMatch: 'prefix'
            },
            {
                title: 'Administration',
                group: true,
            },
            {
                title: 'Utilisateurs',
                icon: 'people-outline',
                link: '/user',
                pathMatch: 'prefix'
            }
        ];
    }

    public toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    }

    public logoutAction(): void {
        this.authService.logout()
            .subscribe(() => {
                this.router.navigateByUrl('/auth');
            })
    }
}
