import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserAdapter } from '../models/adapter/user.adapter';
import { User } from '../models/user.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * Ctor
     * @ignore
     */
    constructor(
        private readonly http: HttpClient,
        private readonly  userAdapter: UserAdapter) { }

    /**
     * Permet de se déconnecter
     */
    public logout(): Observable<void> {
        const URL = `${environment.baseUrl}${environment.boPath}${ApiUrl.LOGOUT}`;
        return this.http.get(URL)
            .pipe(
                map(() => {
                    this.deleteTokens();
                })
            )
    }

    /**
     * Permet de supprimer les tokens du localstorage
     */
    public deleteTokens(): void {
        localStorage.removeItem('wisee-accessToken');
        localStorage.removeItem('wisee-refreshToken');
        localStorage.removeItem('wisee-user');
    }

    /**
     * Permet de récupérer l'utilisateur authentifié
     * @returns Utilisateur courant
     */
    public getConnectedUser(): User | null {
        let userString = localStorage.getItem('wisee-user');
        if (userString) {
            return this.userAdapter.adapt(JSON.parse(userString));
        }
        return null;
    }

    /**
     * Permet de s'authentifier
     * @param email Adresse email de l'utilisateur
     * @param password Mot de passe de l'utilisateur
     * @returns Valeur indiquant si l'utilisateur est authentifié ou non
     */
    public login(email: string, password: string): Observable<boolean | null> {
        const body = {
            email,
            password
        }
        return this.http.post(`${environment.baseUrl}${environment.boPath}${ApiUrl.LOGIN}`, body).pipe(
            map((response: any) => {
                if (response.accessToken) {
                    localStorage.setItem('wisee-accessToken', response.accessToken);
                    localStorage.setItem('wisee-refreshToken', response.refreshToken);
                    localStorage.setItem('wisee-user', JSON.stringify(response.user));
                    return true;
                }
                return false;
            }),
            catchError(err => of(null)),
        );
    }


    /**
     * Permet de récupérer de nouveau token si l'access à expiré
     */
    public refreshTokens(): Observable<string> {
        const URL = `${environment.baseUrl}${environment.boPath}${ApiUrl.REFRESH}`;
        return this.http.get(URL)
            .pipe(
                map((jwts: any) => {
                    localStorage.setItem('wisee-accessToken', jwts.accessToken);
                    localStorage.setItem('wisee-refreshToken', jwts.refreshToken);
                    return jwts.accessToken;
                })
            )
    }

    /**
     * Remonte une valeur indiquant si l'utilisateur est authentifié ou non
     * @returns Authentifié ou non ?
     */
    public isLoggedIn(): boolean {
        const atoken = localStorage.getItem('wisee-accessToken');
        const rtoken = localStorage.getItem('wisee-refreshToken');
        if (atoken && rtoken && atoken.length > 0 && rtoken.length > 0) {
            return true;
        }
        return false;
    }
}
