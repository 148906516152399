import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ApiUrl } from '../services/api.url';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    /**
     * Ctor
     * @ignore
     */
    constructor(
        private readonly authService: AuthService) {
        }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
        let authReq = req;
        let token: string | null = (!req.url.includes(ApiUrl.REFRESH))
            ? localStorage.getItem('wisee-accessToken')
            : localStorage.getItem('wisee-refreshToken')

        if (token != null) {
            authReq = this.addTokenHeader(req, token);
        }

        return next.handle(authReq)
            .pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && !authReq.url.includes(ApiUrl.LOGIN) && error.status === 401) {
                        return this.handle401Error(authReq, next);
                    }
                    return throwError(() => error);
                })
            );
    }

    /**
     * Handle Unauthorized Error, use refreshToken to get a new accessToken, if still fail, go to login page
     * @param request Current request
     * @param next next step
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.refreshTokens().pipe(
                mergeMap((token: string) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token);
                    return next.handle(this.addTokenHeader(request, token));
                }),
                catchError((err) => {
                    this.isRefreshing = false;
                    this.authService.deleteTokens();
                    return throwError(() => err);
                })
            );
        }

        return this.refreshTokenSubject.pipe(
            take(1),
            filter(token => token !== null),
            switchMap((token) => next.handle(this.addTokenHeader(request, token)))
        );
    }

    /**
     * Add Authorization header on request and value it with access token.
     * @param request Current Request
     * @param token Access Token
     * @returns Same request with Authorizaiton Header populated.
     */
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`) });
    }
}
